.integration-price {
  background-color: var(--background-color-gray);
  position: relative;

  &__title {
    display: flex;
    justify-content: center;
  }

  &__title-wrapper {
    overflow: hidden;
  }

  &.need-animation {
    .integration-price {
      &__title, &__user-choose-container {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }

    }
  }

  &.animate {
    .integration-price {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
      &__user-choose-container {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

    }
  }

  &__user-choose-container {
    display: flex;
    align-items: center;
  }

  &__user-select-wrapper {
    position: relative;

    select {
      border: 1px solid var(--border-green-light);
      background-color: white;
      border-radius: 50px;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      appearance: none;
    }

    &:before {
      content: '';
      position: absolute;
      right: 25px;
      top: calc(50% - 4px);
      width: 13px;
      height: 7px;
      background: {
        image: url('/static/images/svg/arrow-green-down.svg');
        repeat: no-repeat;
        size: cover;
      };
    }
  }

  &__subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.55em;
  }

  &__info-wrapper {
    display: flex;
  }

  &__saving-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__saving {
    font-weight: 500;
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 30px;
  }

  &__saving-title {
    margin-top: 33px;
    padding: 3px 13px;
    background-color: var(--background-color-green);
    color: var(--primary-color);
    font-weight: 500;
    font-size: 15px;
    line-height: 155%;
    margin-bottom: 13px;
    border-radius: 50px;
  }

  &__info {
    background-color: white;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
  }

  &__top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__month {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.55em;
    display: flex;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid var(--border-green-light);
  }

  &__month-icon {
    margin-right: 14px;
    svg {
      width: 28px;
      height: 26px;
    }
  }

  &__top-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 1.35em;
    letter-spacing: -0.02em;
  }

  &__install-wrapper {
    position: absolute;
    bottom: -65px;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: var(--default-transition);

    .button {
      font-weight: 500;
      font-size: 17px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      color: white;

      padding: 22px 0;
      border-radius: 0 0 10px 10px;
      display: flex;
      justify-content: center;
    }
  }

  &__discount-wrapper {
    display: flex;
    justify-content: center;
  }

  &__discount-text {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.55em;
    color: var(--green-text);
    padding: 8px 25px;
    border-radius: 50px;
    background-color: var(--background-color-green);
  }

  &__bottom-content {
    position: relative;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
  }

  &__bottom-content-wrapper {
    display: flex;
    justify-content: space-between;
    margin: -8px;
  }

  &__bottom {
    position: relative;
  }

  &__bottom-wrapper {
    position: relative;
    z-index: 20;
  }

  &__bottom-text {
    font-weight: normal;
    line-height: 1.35em;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    padding: 8px;
  }

  &__button-bottom-wrap {
    padding: 8px;
  }

  &__button-bottom {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2em;
    letter-spacing: -0.02em;
    color: white;
  }

  &__text-strong {
    font-weight: bold;
  }

  &__back {
    position: absolute;
    height: 50%;
    z-index: 10;
    left: 0;
    right: 0;
    background-color: white;
    bottom: 0;
  }
}

@include respond-up('large') {
  .integration-price {
    padding-top: 130px;

    &__user-choose-container {
      margin-top: 60px;
    }

    &__info-wrapper {
      margin-top: 60px;
    }

    &__top-title {
      margin-top: 38px;
    }

    &__subtitle {
      margin-right: 26px;
    }

    &__bottom-text {
      font-size: 19px;
    }

    &__bottom-raw-text {
      strong {
        font-size: 21px;
        font-weight: bold;
      }
    }

    &__month {
      padding: 30px 0;
    }

    &__user-select-wrapper {
      select {
        padding: 21px 182px 21px 30px;
      }
    }

    &__info {
      margin-right: 16px;
      width: 100%;

      &:hover {
        border-radius: 10px 10px 0 0;
        .integration-price {
          &__install-wrapper {
            opacity: 1;
            pointer-events: auto;
            transition: var(--default-transition);
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }

      &:nth-child(2) {
        transform: translate3d(0, 50px, 0);
      }
      &:nth-child(3) {
        transform: translate3d(0, 40px, 0);
      }
      &:nth-child(3) {
        transform: translate3d(0, 30px, 0);
      }
    }

    &__discount-wrapper {
      padding: 38px 0 30px;
    }

    &__bottom {
      margin-top: 109px;
    }

    &__bottom-content {
      padding: 50px 65px;
    }

    &__button-bottom {
      padding: 22px 79px 22px 22px;
    }
  }
}

@include respond-down('medium') {
  .integration-price {
    padding-top: 30px;

    &__user-choose-container {
      margin-top: 20px;
      align-items: flex-start;
    }

    &__user-choose-container {
      flex-direction: column;
    }

    &__info-wrapper {
      margin-top: 30px;
      flex-direction: column;
      width: 100%;
    }
    &__bottom-text {
      font-size: 18px;
    }

    &__month {
      padding: 25px 0 24px;
    }

    &__top-title {
      margin: 24px 0;
    }

    &__subtitle {
      margin-right: 0;
      margin-bottom: 8px;
    }

    &__user-select-wrapper {
      width: 100%;

      select {
        padding: 16px 183px 16px 20px;
        width: 100%;
      }
    }

    &__info {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__discount-wrapper {
      padding: 0 0 25px;
    }

    &__bottom-content {
      padding: 30px 25px;
    }

    &__bottom-content-wrapper {
      flex-wrap: wrap;
    }

    &__button-bottom {
      padding: 17px 79px 17px 23px;
    }

    &__bottom {
      margin-top: 25px;
    }
  }
}