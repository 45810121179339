:root{
  --modal-bg: rgba(0, 0, 0, 0.76);
  --modal-content-radius: 5px;
  --modal-content-shadow: 3px 4px 35px rgba(92, 99, 100, 0.27);
  --modal-content-padding: 30px;
  --modal-content-background: #fff;
  --modal-closer-color: var(--modal-bg);
  --modal-closer-size: 30px;
  --modal-preloader-size: 30px;
}

.modal__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  background: white;
  background: var(--modal-bg);
  z-index: 10000;
  display: none;
  top: 0;
  left: 0;
  overflow-y: scroll;

  &.opened {
    display: block;
  }
}

.modal__layout {
  display: none;
  position: relative;
  z-index: 11000;
  cursor: default;
  padding: 80px 0;
  margin: 0 auto;
  min-height: 100%;
  align-items: center;
  justify-content: center;

  &.opened {
    display: flex;
  }
}

.modal__container {
  max-width: 788px;
  padding: var(--modal-content-padding);
  border-radius: var(--modal-content-radius);
  background: var(--modal-content-background);
  box-shadow: var(--modal-content-shadow);
  position: relative;
}

.modal__content {
  img {
    max-width: 100%;
    height: auto;
  }
}

.modal__closer {
  position: absolute;
  top: 0;
  right: -10%;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;

  &:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: {
      image: url('/static/images/base/closer.png');
      repeat: no-repeat;
      color: rgba(255, 255, 255, 0.1);
      size: cover;
    };
  }



 font-size: var(--modal-closer-size);
 color: var(--modal-closer-color);

 text-decoration: none;
 cursor: pointer;
}

.modal__loader {
 display: block;
 position: fixed;
 z-index: 3000;
 left: 0;
 right: 0;
 bottom: 0;
 top: 0;
 background-color: var(--modal-bg);
 transition: 0.3s all;
 opacity: 0;
 visibility: hidden;
}

body.modal-loading {
 overflow: hidden;
 --primary-color-opacity: rgba(1, 199, 103, .85);
 --preloader-size: 100px;

 .modal__loader {
   opacity: 1;
   visibility: visible;

   &:before, &:after {
     content: '';
     position: fixed;
     width: var(--preloader-size);
     height: var(--preloader-size);
     left: 50%;
     top: 50%;
     margin-left: calc(var(--preloader-size)/-2);
     margin-top: calc(var(--preloader-size)/-2);
     background-color: #000;
     border-radius: calc(var(--preloader-size)/2);
     transform: scale(0);
   }

   &:before {
     background-color: var(--primary-color-opacity, var(--primary-color));
     animation-name: pulse;
     animation-iteration-count: infinite;
     animation-duration: 1.4s;
     animation-delay: 0.4s;
   }

   &:after {
     background-color: var(--primary-color);
     animation-name: pulse;
     animation-iteration-count: infinite;
     animation-duration: 1.4s;
   }
 }
}

@keyframes pulse {
 0% {
   transform: scale(0);
 }

 50% {
   transform: scale(1);
 }

 100% {
   transform: scale(0);
 }
}

@include respond-down('medium') {
  .modal__layout {
    padding: 0;
    margin: 0 auto;
    min-height: 100%;
    justify-content: center;

    &.opened {
      display: flex;
    }
  }

  .modal__container {
    height: 100vh;
    width: 100%;
    border-radius: 0;
  }


  .modal__closer {
    position: absolute;
    top: 10px;
    right: 22px;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    box-shadow: 0 6px 12px rgba(52, 97, 81, 0.07);

    &:before {
      width: 13px;
      height: 13px;
      background: {
        image: url('/static/images/base/green-closer.png');
        repeat: no-repeat;
        color: rgba(255, 255, 255, 0.1);
        size: cover;
      }
    ;
    }
  }
}