.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {

  }

  &__title {
    font-weight: bold;
    letter-spacing: -0.02em;
  }

  &__subtitle {
    line-height: 135%;
    letter-spacing: -0.02em;
  }

  &__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2em;
    letter-spacing: -.02em;
    color: white;
    padding: 22px 79px 22px 22px;
  }
}

@include respond-up('large') {
  .error {
    padding: 110px 0 137px;
    &__image {
      margin-bottom: 62px;
    }

    &__title {
      font-size: 42px;
      line-height: 110%;
      margin-bottom: 16px;
    }

    &__subtitle {
      font-size: 17px;
      margin-bottom: 32px;
    }

    &__button {

    }
  }
}
@include respond-down('medium') {
  .error {
    padding: 80px 0 100px;
    &__image {
      margin-bottom: 40px;
    }

    &__title {
      font-size: 30px;
      line-height: 110%;
      margin-bottom: 20px;
    }

    &__subtitle {
      font-size: 16px;
      line-height: 135%;
      margin-bottom: 30px;
      text-align: center;
    }

    &__button {

    }
  }
}