.why-slider {
  &__list {
    margin-bottom: 30px;
  }

  &__arrows-container {
    margin: 0 -10px;
  }

  &__arrow {
    padding: 0 10px;
  }

  &__arrows-container {
    display: flex;
    justify-content: center;
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    letter-spacing: -0.02em;
    margin-bottom: 11px;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text);
  }

  .tns-nav {
    display: none;
  }

  &__image-wrapper {
    background-color: white;
    border: 1px solid var(--border-green-light);
    box-sizing: border-box;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    border-radius: 10px;
    padding: 6px;
    margin-bottom: 30px;

    img {
      width: 100%;
      border-radius: 6px;
      display: block;
    }
  }
}