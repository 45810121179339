.press {
  background: var(--background-color-another-gray);
  padding: 130px 0;

  &__wrapper {
    position: relative;
  }

  &.need-animation {
    .press {
      &__title, &__slider-arrows-container {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }
    }
  }

  &.animate {
    .press {
      &__title, &__slider-arrows-container {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    padding-bottom: 60px;
  }

  &__articles {
    display: flex;
  }

  &__article {
    padding: 36px;
    background: white;
    border-radius: 10px;
    flex: 0 0 388px;
    height: 325px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 17px;
  }

  &__link {
    .press {
      &__text {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__text {
    font-size: 19px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-border-color);
  }

  &__date {
    font-weight: 500;
    font-size: 14px;
    line-height: 125%;
    letter-spacing: -0.02em;
    color: var(--green-text);
  }

  &__pictures {
    display: flex;
    justify-content: space-between;
  }

  &__preview {
    flex: 0 0 128px;

    img {
      max-height: 73px;
      border-radius: 6px;
    }
  }

  &__logo {
    flex: 0 0 107px;
    text-align: right;

    img {
      max-height: 38px;
    }
  }

  &__slider-arrows-container {
    position: absolute;
    top: 8px;
    right: 0;
  }

  &__slider-arrows-container-one, &__slider-arrows-container-two, &__slider-arrows-container-tree {
    display: none;
  }

  &__slider-arrows {
    display: flex;
    margin-right: -10px;
  }

  &__slider-arrow {
    margin-right: 20px;
  }
}

@include respond-up('large') {
  .press {
    &__article-wrapper {
      transition: var(--default-transition);
      &:nth-child(2) {
        transform: translate3d(0, 40px, 0);
      }

      &:nth-child(3) {
        transform: translate3d(0, 30px, 0);
      }
    }
  }
}

@include respond-down('medium') {
  .press {
    padding: 30px 0;

    &__title {
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
    }

    &__article {
      padding: 25px;
      height: 242px;
      flex: 0 0 291px;
      margin-right: 10px;
    }

    &__text {
      font-size: 17px;
      max-height: 70px;
      overflow: hidden;
    }

    &__preview {
      flex: 0 0 106px;

      img {
        max-height: 60px;
      }
    }

    &__logo {
      flex: 0 0 91px;

      img {
        max-height: 32px;
      }
    }

    &__slider-arrows-container {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      position: relative;
      top: 0;
    }

    &__slider-arrows-container-one, &__slider-arrows-container-two {
      display: none;
    }

    &__slider-arrows-container-tree {
      display: flex;
    }
  }
}

@include respond-down('small') {
  &__slider-arrows-container-two {
    display: flex;
  }
}
