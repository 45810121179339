.why {
  background-color: white;

  &__title {
    text-align: left;
  }

  &.need-animation {
    .why {
      &__title, &__list, &__image-wrapper {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }
    }
  }

  &.animate {
    .why {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__list {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__image-wrapper {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.9s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
    }
  }

  &__title-wrapper {
    overflow: hidden;
  }

  &__image-wrapper {
    background-color: white;
    border: 1px solid var(--border-green-light);
    box-sizing: border-box;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    border-radius: 10px;

    img{
      border-radius: 6px;
      display: block;
    }
  }

  &__image-item{
    display: none;

    &._opened{
      display: initial;
    }
  }
}

@include respond-up('large') {
  .why {
    padding-bottom: 100px;
    &__body {
      display: flex;
      justify-content: space-between;
    }

    &__mobile-slider {
      display: none;
    }

    &__text-wrapper {
      flex: 0 0 40%;
      width: 40%;
    }

    &__title {
      margin-bottom: 49px;
    }

    &__list {
    margin: -18px 0 0;
    }

    &__item {
      padding: 18px 0;
      border-bottom: 2px solid var(--grey-light-border-color);

      &:hover {
        .why__item-tab-name {
          color: black;
          opacity: 1;
        }
      }
      &._opened {
        .why {
          &__item-content-container {
            opacity: 1;
          }

          &__item-tab-name {
            color: black;
            opacity: 1;
          }

          &__item-tab-icon {
            transform: rotate(-90deg);
          }

          &__item-tab-icon-minus {
            opacity: 0;
          }
        }
      }
    }

    &__item-tab {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__item-tab-name {
      transition: var(--default-transition);
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;
      letter-spacing: -0.02em;
      opacity: 0.4;
      color: var(--grey-text);
    }

    &__item-tab-icon {
      transition: var(--default-transition);
      position: relative;
      height: 15px;
      width: 15px;
    }

    &__item-tab-icon-plus {
      position: absolute;
      left: 6px;
      width: 3px;
      height: 15px;
      background-color: var(--border-primary-color);
    }

    &__item-tab-icon-minus {
      position: absolute;
      height: 3px;
      width: 15px;
      top: 6px;
      background-color: var(--border-primary-color);
      opacity: 1;
      transition: var(--default-transition);
    }

    &__item-content-container {
      transition: var(--default-transition);
      height: 0;
      opacity: 0;
      pointer-events: none;
    }

    &__item-content {
      padding-top: 12px;
      font-size: 17px;
      line-height: 135%;
      letter-spacing: -0.02em;
      color: var(--grey-text);
    }

    &__image-wrapper {
      max-height: 369px;
      flex: 0 0 652px;
      width: 652px;
      padding: 6px;
    }
  }
}

@include respond-down('medium') {
  .why {
    margin-bottom: 30px;

    &__title {
      text-align: center;
      margin-bottom: 20px;
    }

    &__accordion {
      display: none;
    }

    &__image-wrapper {
      padding: 3px;
      margin-bottom: 20px;
    }
  }
}