.arrow-round {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  position: relative;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:before {
    transition: var(--default-transition);
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: 0;
    opacity: 0.4;
    border: 1px solid var(--green-arrow);
    width: 49px;
    height: 49px;
  }

  &_right {
    &:before {
      left: -2px;
    }
  }
}