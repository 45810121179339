.tns-nav {
  button {
    width: 11px;
    height: 11px;
    margin-right: 17px;
    border-radius: 8px;
    background-color: var(--disable);

    &.tns-nav-active {
      background-color: var(--border-primary-color);
      width: 30px;
      height: 15px;
    }
  }
}

.advantages__slider, .why-slider {
  .tns-nav {
    display: none;
  }
}

.press {
  .tns-nav {
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-down('medium'){
  .press {
    .tns-nav {
      display: none;
    }
  }
}