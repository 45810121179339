.interferes-earning {
  &__title {
    text-align: center;
  }

  &__title-wrapper {
    overflow: hidden;
  }

  &.need-animation {
    .interferes-earning {
      &__title, &__content-left, &__content-right, &__tabs {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }
    }
  }

  &.animate {
    .interferes-earning {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__tabs {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__content-right {
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 1.2s;
          duration: 1s;
          timing-function: var(--default-transition);
        };
      }

      &__content-left {
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 1.4s;
          duration: 1s;
          timing-function: var(--default-transition);
        };
      }
    }
  }

  &__tabs {
    li {
      a {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &__tab {
    width: 100%;
    height: 100%;

    &._active {
      a {
        background-color: white;
        box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
      }
    }

    &:nth-child(1) {
      .interferes-earning__link {
        background: {
          image: url('../images/svg/icons/leads.svg');
        };
      }
    }

    &:nth-child(2) {
      .interferes-earning__link {
        background: {
          image: url('../images/svg/icons/kpi.svg');
        };
      }
    }

    &:nth-child(3) {
      .interferes-earning__link {
        background: {
          image: url('../images/svg/icons/rop.svg');
        };
      }
    }

    &:nth-child(4) {
      .interferes-earning__link {
        background: {
          image: url('../images/svg/icons/employees.svg');
        };
      }
    }
  }

  &__link {
    border: 1px solid var(--green-border-color);
    border-radius: 10px;
    background: {
      repeat: no-repeat;
      size: 40px;
    };
  }

  &__tab-name {
    font-weight: normal;
    letter-spacing: -0.02em;
  }

  &__content {
    &._active {
      display: flex;
    }
  }

  &__content-title {
    font-weight: 500;
    line-height: 1.3em;
    letter-spacing: -0.02em;
  }

  &__content-description {
    font-weight: normal;
    line-height: 1.35em;
    letter-spacing: -0.02em;
    color: var(--grey-text);
    margin-top: 15px;
  }

  &__content-image {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2em;
    letter-spacing: -0.02em;
    color: white;
    padding: 22px 79px 22px 22px;
  }

  &__picture {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  &__content-right {
    border-radius: 10px;
    border: 1px solid var(--border-green-light);
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    padding: 6px;
  }
}

@include respond-up('large') {
  .interferes-earning {
    &__title {
      padding: 90px 0 60px;
    }

    &__tab-name {
      font-size: 17px;
      line-height: 1.35em;
    }

    &__tabs {
      margin: 0 -7px;
    }

    &__tab {
      padding: 0 7px;
    }

    &__content-wrapper {
      padding: 55px 0 133px;
      position: relative;
    }

    &__link {
      padding: 19px;
      min-height: 117px;
      background: {
        position: top 20px left 20px;
      };
    }

    &__tab-name {
      font-size: 17px;
      line-height: 1.35em;
    }

    &__content {
      &._active {
        justify-content: space-between;
      }
    }

    &__content-left {
      flex: 0 0 35%;
    }

    &__content-right {
      flex: 0 0 58%;
      min-height: 389px;
    }

    &__content-title {
      font-size: 22px;
    }

    &__content-description {
      font-size: 17px;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__button {
      padding: 22px 79px 22px 22px;
    }

    &__button-wrap-mobile {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .interferes-earning {
    &__title {
      padding: 0 0 21px;
    }

    &__tabs-wrapper {
      margin: -20px -20px -80px -22px;
      padding: 20px 0 77px;
      overflow: hidden;

      .tns-ovh {
        overflow: visible;
      }
    }

    &__tabs {
      margin: -20px 0 -80px -8px;
      padding: 20px 20px 80px 27px;
      overflow: scroll;

      li {
        a {
          justify-content: center;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tab {
      padding: 3px;
    }

    &__tab-name {
      min-height: 30px;
      font-size: 14px;
      line-height: 1.1em;
      text-align: center;
    }

    &__link {
      padding: 10px;
      min-height: 100px;
      min-width: 150px;
      background: {
        position: top 10px center;
      };
    }

    &__content {
      &._active {
        flex-direction: column;
      }
    }

    &__content-title {
      font-size: 20px;
    }

    &__content-description {
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__content-wrapper {
      padding: 30px 0;
    }

    &__content-right {
      height: calc((187 / 375) * 100vw);
    }

    &__button-wrap-desktop {
      display: none;
    }

    &__button-wrap-mobile {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }

    &__button {
      padding: 17px 79px 17px 23px;
    }
  }
}