.footer {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey-light-border-color);
  }

  &__list {
    display: flex;
    align-items: center;
  }

  &__link {
    display: inline-block;
    position: relative;
    padding-bottom: 3px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: var(--grey-border-color);
      transform: scaleX(0);
      transform-origin: right;
      transition: transform .3s ease-out;
    }

  }

  &__mail {
    color: var(--green-text);
    transition: var(--default-transition);
  }

  &__contacts {
    display: flex;
    align-items: center;
  }

  &__phone {
    font-weight: bold;
  }

  &__socials-button {
    margin: 0 3px;
    background-color: white;
    border: 1px solid var(--grey-light-border-color);
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0 5px 30px rgba(26, 53, 94, 0.08);
    border-radius: 50%;
    position: relative;
    transition: var(--default-transition);

    &:nth-child(2) {
      .footer__socials-icon {
        top: 8px;
        left: 10px;
      }
    }
  }

  &__socials-icon {
    position: absolute;
    height: 16px;
    transition: var(--default-transition);

    svg {
      path {
        transition: var(--default-transition);
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
  }

  &__insta-button {
    padding: 11px 20px;
    display: inline-flex;
    align-items: center;
    background-color: white;
    border: 1px solid var(--border-green-light);
    box-sizing: border-box;
    box-shadow: 0 5px 30px rgba(26, 53, 94, 0.08);
    border-radius: 50px;
    transition: var(--default-transition);
  }


  &__insta-button-icon {
    padding-right: 10px;
    transition: var(--default-transition);
    height: 18px;

    svg {
      path {
        transition: var(--default-transition);
      }
    }
  }

  &__insta-button-name {
    font-weight: normal;
    font-size: 15px;
    line-height: 155%;
    color: var(--grey-text);
    transition: var(--default-transition);
  }

  &__politic-wrapper {
    display: flex;
    font-weight: normal;
    font-size: 13px;
    line-height: 125%;
    color: var(--grey-text);
  }

  &__politic-name {
    border-bottom: 1px solid var(--grey-light-border-color);
    padding-bottom: 1px;
    transition: var(--default-transition);
  }

  &__link-right {
    color: var(--green-text);
    transition: var(--default-transition);
  }
}

@include respond-up('large') {
  .footer {
    padding: 35px 60px;

    &__logo {
      max-width: 109px;
      max-height: 27px;
    }

    &__top {
      padding-bottom: 36px;
      margin-bottom: 23px;
    }

    &__phone {
      font-weight: 500;
      font-size: 17px;
      line-height: 120%;
      margin: 0 39px;
    }

    &__contacts {
      flex: 0 0 35%;
      display: flex;
      justify-content: flex-end;
    }

    &__socials-wrapper {
      margin: 0 -3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__list {
      margin: 0 -10px;
    }

    &__item {
      padding: 0 10px;

      &:hover {
        .footer__link {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s cubic-bezier(.25, .1, .25, 1);
          }
        }
      }
    }

    &__insta-button {
      max-width: 150px;
      background: white;
      border: 1px solid var(--border-green-light);
      box-sizing: border-box;
      box-shadow: 0 5px 30px rgba(26, 53, 94, 0.08);
      border-radius: 50px;

      &:hover {
        border: 1px solid var(--primary-color);
      }
    }

    &__bottom {
      display: grid;
      grid-template-columns: 1fr 453px;
    }

    &__politic-wrapper {
      flex: 0 0 40%;
      display: flex;
      justify-content: space-between;
    }

    &__politic-link {
      &:hover {
        .footer__politic-name {
          border-color: var(--grey-text);
        }
      }
    }

    &__mail {
      &:hover {
        color: var(--hover-color);
      }
    }

    &__link-right {
      &:hover {
        color: var(--hover-color);
      }
    }

    &__socials-button {

      &_viber {
        &:hover {
          background-color: var(--hover-viber);
        }
      }

      &_whatsapp {
        &:hover {
          background-color: var(--primary-color);
        }
      }

      &_telegram {
        &:hover {
          background-color: var(--hover-telegram);
        }
      }

      &:hover {
        svg {
          path {
            fill: white;
          }
        }
      }
    }
  }
}

@include respond-down('medium') {
  .footer {
    padding: 25px 22px 30px;

    &__item {
      padding: 10px 7px;
    }

    &__list {
      margin: -10px -7px 23px;
      flex-wrap: wrap;
    }

    &__top {
      display: grid;
      grid-template-areas: 'logo' 'contacts' 'mail' 'menu';
      border-bottom: none;
    }

    &__logo {
      grid-area: logo;
      margin-bottom: 15px;
      max-width: 90px;
      max-height: 22px;
    }

    &__menu-wrapper {
      grid-area: menu;
    }

    &__mail {
      order: 3;
      grid-area: mail;
    }

    &__socials-wrapper {
      display: flex;
      margin-bottom: 10px;
    }

    &__contacts {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      grid-area: contacts;
      margin-bottom: 30px;
    }

    &__phone {
      @media screen and (max-width: 354px) {
        flex: 0 0 100%;
        margin-bottom: 11px;
      }
    }

    &__menu-wrapper {
      flex-wrap: wrap;
      flex: 0 0 100%;
    }

    &__insta-button {
      margin-bottom: 31px;
    }

    &__politic-wrapper {
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      justify-content: space-between;
      border-top: 1px solid var(--grey-light-border-color);
    }

    &__bottom {
      flex-wrap: wrap;
    }

    &__politic-link {
      padding-bottom: 20px;
    }

    &__brand {
      padding-bottom: 20px;
      flex: 0 0 30%;
    }
  }
}