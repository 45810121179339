.title {
  font-weight: bold;
  line-height: 110%;
  letter-spacing: -0.02em;

  &_center{
    text-align: center;
  }
}

@include respond-up('large') {
  .title {
    font-size: 50px;

    &_small {
      font-size: 45px;
    }
  }
}

@include respond-down('medium') {
  .title {
    font-size: 28px;
    text-align: center;

    &_small {
      font-size: 25px;
    }
  }
}
