.ajax-form {
  &__container {

  }

  &__success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__success-icon {
    width: 87px;
    height: 87px;
    border-radius: 100%;
    box-shadow: 0 20px 20px rgba(52, 97, 81, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;

    svg {
      width: 33px;
      height: 33px;
    }
  }

  &__success-title {
    font-weight: bold;
    line-height: 110%;
    letter-spacing: -0.02em;
  }

  &__success-description {
    line-height: 135%;
    letter-spacing: -0.02em;
    color: var(--grey-text);
  }
}

@include respond-up('large') {
  .ajax-form {
    &__container {
      padding: 70px 95px 130px;
    }

    &__success {

    }

    &__success-icon {

    }

    &__success-title {
      font-size: 40px;
      margin-bottom: 25px;
    }

    &__title {

    }

    &__success-description {
      font-size: 17px;

    }
  }
}

@include respond-down('medium') {
  .ajax-form {
    padding-top: 70px;
    &__container {

    }

    &__success {

    }

    &__success-icon {
      margin-bottom: 41px;
    }

    &__success-title {
      text-align: center;
      font-size: 25px;
      margin-bottom: 15px;
    }

    &__success-description {
      font-size: 16px;
      text-align: center;

    }
  }
}