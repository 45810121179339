.optimization {
  position: relative;

  &__title-wrapper {
    overflow: hidden;
  }

  &.need-animation {
    &::before, &::after {
      opacity: 0;
    }

    .optimization {
      &__title {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__third-before {
        &::before {
          opacity: 0;
        }
      }

      &__bottom {
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__subtitle {
        opacity: 0;
        transform: translate3d(0, 10px, 0);
      }

      &__button-wrapper {
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__circle-green, &__circle-white {
        transform: translate3d(0, 150px, 0);
        opacity: 0;
      }
    }
  }

  &.animate {
    &::before, &::after {
      opacity: 1;
      transition: var(--default-transition);
      transition-duration: 1.5s;

    }

    .optimization {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__third-before {
        &::before {
          opacity: 1;
          transition: var(--default-transition);
          transition-duration: 1.5s;
        }
      }

      &__bottom {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__subtitle {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__button-wrapper {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__circle-green, &__circle-white {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 2.5s;
          timing-function: ease-in-out;
        };
      }
    }
  }


  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    top: -80px;

    &:before {
      position: absolute;
      content: '';
      width: 70%;
      height: 100%;
      background: radial-gradient(50% 50% at 50% 50%, #BDFEF7 0%, rgba(189, 254, 247, 0) 99.34%);
      right: -20%;
      bottom: -20%;
      z-index: 1;
    }

    &:after {
      position: absolute;
      content: '';
      width: 50%;
      height: 100%;
      background: radial-gradient(45.63% 47.98% at 50% 50%, #9FFF9C 0%, rgba(160, 254, 157, 0) 100%);
      right: -20%;
      top: -20%;
      z-index: 1;
    }
  }

  &__button {
    transition: var(--default-transition);
    background: linear-gradient(165.82deg, #0DDB21 5.59%, #26CBCD 117.77%), #C4C4C4;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
      transform: scale(1.1);
    }

    &:before {
      content: '';
      position: absolute;
      border: 1px solid rgba(74, 194, 107, 0.23);
      top: 0;
      right: 0;
      width: 59px;
      height: 59px;
      border-radius: 100%;
      animation: pulsate 2s linear;
      animation-iteration-count: infinite;
    }

    &::after {
      content: '';
      position: absolute;
      border: 1px solid rgba(74, 194, 107, 0.08);
      right: 0;
      width: 67px;
      height: 67px;
      border-radius: 100%;
      top: -4px;
      left: -5px;
      animation: pulsate 1.9s linear;
      animation-iteration-count: infinite;
      animation-delay: 1s;
    }
  }

  &__wrapper {
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__subtitle {
    line-height: 140%;
    letter-spacing: -0.02em;
    color: var(--grey-text);
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
    background-color: var(--background-color-gray);
    border-radius: 10px;
    border: 1px solid var(--border-green-light);
    box-sizing: border-box;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    z-index: 30;
    position: relative;
  }

  &__picture {
    width: 100%;
    z-index: 30;
    position: relative;
  }

  &__left-side {
    display: block;
  }

  &__right-side-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 85px 85px 170px;
    grid-gap: 21px;
  }

  &__task {
    grid-row: 2/3;
  }

  &__call {
    grid-row: 3/3;
    grid-column: 2/2;
    margin-top: -20px;
  }

  &__balans, &__pay, &__task, &__call {
    border-radius: 5px;
  }

  &__third-before {
    &:before {
      position: absolute;
      content: '';
      width: 70%;
      height: 40%;
      background: radial-gradient(45.63% 47.98% at 50% 50%, #e2f8ce 0, rgba(225, 248, 206, 0) 100%);
      z-index: 1;
      bottom: -15%;
      left: -5%;
    }
  }

  &__white-block {
    width: 100%;
    height: 80px;
    background-color: white;
    z-index: 20;
    position: relative;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    transform: scale(1.2);
    opacity: 0.0;
  }
}

@include respond-up('large') {
  .optimization {
    padding-top: 80px;

    &__title {
      text-align: center;
      margin-bottom: 22px;
    }

    &__left-side {
      transform: translate3d(-40px, -25px, 0);
    }

    &__balans {
      transform: translate3d(10px, -35px, 0);
    }

    &__pay {
      transform: translate3d(100px, -45px, 0);
    }

    &__task {
      transform: translate3d(-70px, 45px, 0);
    }

    &__call {
      transform: translate3d(100px, 15px, 0);
    }

    &__right-side-container {
      padding: 21px 55px 37px 0;
    }

    &__button {
      width: 60px;
      height: 60px;
    }


    &__subtitle {
      font-size: 18px;
      margin: 0 auto 52px;
      text-align: center;
      max-width: 850px;
    }

    &__button-wrapper {
      display: flex;
      justify-content: center;
      margin-bottom: 29px;
    }

    &__circle-green {
      position: absolute;
      left: 0;
      top: 69px;

    }

    &__circle-white {
      position: absolute;
      right: 0;
      bottom: 160px;
      z-index: 10;
    }


    &__bottom {
      z-index: 30;
      position: relative;
      margin-bottom: -45px;
    }

    &__wrapper {

    }

    &__mobile-img {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .optimization {
    margin-top: -80px;
    padding-top: 86px;


    &__title {
      text-align: center;
      margin-bottom: 20px;
    }

    &__mobile-img {
      width: calc((316/375) * 100vw);
      height: calc((175/375) * 100vw);
    }

    &__left-side {
      display: none;
    }

    &__right-side-container {
      display: none;
    }

    &__button {
      width: 46px;
      height: 46px;

      &:before {
        width: 44px;
        height: 44px;
      }

      &::after {
        width: 54px;
        height: 54px;
        top: -5px;
      }
    }

    &__subtitle {
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
    }

    &__circle-green, &__circle-white {
      display: none;
    }

    &__button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__bottom {
      padding: 4px;
      margin-bottom: -37px;
    }

    &.need-animation {
      .optimization {
        &__bottom {
          opacity: 0;
          transform: translate3d(0, 50px, 0);
        }
      }
    }

    &.animate {
      .optimization {
        &__bottom {
          transform: translate3d(0, 0, 0);
          opacity: 1;
          transition: {
            property: transform, opacity;
            delay: 1s;
            duration: 1s;
            timing-function: ease-in-out;
          };
        }
      }
    }

    &__third-before {
      &:before {
        left: -40%;
        bottom: -10%;
      }
    }
  }
}