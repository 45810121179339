.button {
  padding: 0;
  margin: 0;
  text-align: center;
  transition: var(--default-transition);
  display: inline-block;
  border: none;
  background: var(--primary-color);
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;

  &_expand, &_wide {
    width: 100%;
  }

  &_round {
    border-radius: 100px;
  }

  &_green {
    position: relative;
    display: inline-block;
    background: linear-gradient(99.58deg, #37ED5F 7.99%, #26CBCD 74.77%), #C4C4C4;
    transition: var(--default-transition);
    z-index: 1;

    &::before {
      border-radius: inherit;
      background: linear-gradient(0deg, #37EC62, #37EC62), #C4C4C4;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      transition: var(--default-transition);
      z-index: -1;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &_plus {
    &::after {
      content: '';
      background-color: var(--t);
      width: 20px;
      height: 20px;
      right: 23px;
      position: absolute;
      transition: var(--default-transition);
      background: {
        image: url('../images/svg/plus.svg');
        repeat: no-repeat;
        size: cover;
      };
    }

    &:hover {
      &::after {
        transform: rotate(-90deg);
        transition-timing-function: ease;
      }
    }
  }

  &_arrow {
    &::after {
      content: '';
      background-color: var(--t);
      width: 21px;
      height: 20px;
      right: 23px;
      position: absolute;
      transition: var(--default-transition);
      background: {
        image: url('../images/svg/arrow.svg');
        repeat: no-repeat;
        size: cover;
      };
    }

    &:hover {
      &::after {
        transform: translate(2px, -2px);
        transition-timing-function: ease-in-out;
      }
    }
  }
}

@include respond-down('medium') {
  .button {
    &_plus {
      &::after {
        top: 17px;
      }
    }
  }
}