.heading {
  font-size: 22px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--grey-border-color);
}

.text {
  font-size: 17px;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: var(--grey-text);
}

@include respond-down('medium') {
  .heading {
    font-size: 20px;
  }

  .text {
    font-size: 16px;
  }
}