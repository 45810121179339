.header {
  transition: var(--default-transition);
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;

  &.need-animation {
    .header {
      &__inner {
        overflow: hidden;
        transform: translate3d(0, -20px, 0);
        opacity: 0;
      }
    }
  }

  &.animate {
    .header {
      &__inner {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: .4s;
          timing-function: var(--outline-timing-function);
        };
      }
    }
  }

  &._fixed {
    height: 80px;
    transform: translate3d(0, 0, 0);
    background-color: white;
    box-shadow: 0 10px 20px rgba(52, 97, 81, 0.1);
  }

  &__logo {
    display: block;
  }

  &__img {
    display: block;
  }

  &__inner {
    transition: var(--default-transition);
    z-index: 100;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__button {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.02em;
  }
}

@include respond-up('large') {
  .header {
    &__inner {
      padding: 0 60px;
      height: 80px;
    }

    &__mobile-block {
      display: none;
    }

    &__mobile-number-button {
      display: none;
    }

    &__list {
      display: flex;
    }

    &__item {
      padding: 0 10px;

      &:hover {
        .header__link {
          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s cubic-bezier(.25, .1, .25, 1);
          }
        }
      }
    }

    &__link {
      border-bottom: 1px solid transparent;
      position: relative;
      transition: all .4s;
      padding-bottom: 4px;
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.02em;

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: var(--grey-border-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__number {
      font-weight: 500;
      font-size: 17px;
      line-height: 120%;
      margin-right: 40px;
    }

    &__button-wrapper {
      position: relative;
      margin-bottom: 3px;

      &:hover {
        .header__button {
          border-color: var(--t);

          &::before {
            left: 0;
            transform-origin: left;
            transform: scaleX(1);
            transition: transform .3s cubic-bezier(.25, .1, .25, 1) .3s;
          }

          &::after {
            left: 0;
            transform-origin: right;
            transform: scaleX(0);
            transition: transform .3s cubic-bezier(.25, .1, .25, 1);
          }
        }
      }
    }

    &__button {
      padding-bottom: 5px;
      transition: var(--default-transition);

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2px;
        background-color: var(--grey-border-color);
        transform: scaleX(0);
        transform-origin: right;
        transition: transform .3s ease-out;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: -6px;
        width: 100%;
        height: 2px;
        background-color: var(--grey-border-color);
        transform: scaleX(1);
        transform-origin: left;
        transition: transform .3s ease-out;
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    height: 80px;
    &._opened {
      background-color: white;
    }

    &__mobile-header {
      display: block;
      position: relative;
      height: 16px;
      width: 31px;

      &._opened {
        .header__hamburger-line {
          &:nth-child(1) {
            transform: rotate(45deg) translateY(-4px);
          }

          &:nth-child(2) {
            transform: translateX(-100%);
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg) translateY(4px);
          }
        }
      }
    }

    &__hamburger-line {
      display: block;
      position: absolute;
      width: 31px;
      height: 2px;
      background-color: var(--grey-border-color);
      transition: {
        duration: .3s;
        property: transform, opacity;
        timing-function: ease-in-out;
      };

      &:nth-child(1) {
        top: calc(50% - 10px);
        transform-origin: left top;
      }

      &:nth-child(2) {
        top: calc(50% - 2px);
      }

      &:nth-child(3) {
        top: calc(50% + 6px);
        transform-origin: left bottom;
      }

    }

    &__expand-block {
      pointer-events: none;
      padding: 0 24px 31px;
      justify-content: center;
      position: absolute;
      top: 80px;
      left: 0;
      opacity: 0;
      width: 100%;
      background-color: white;
      transition: var(--default-transition);

      &._opened {
        pointer-events: auto;
        opacity: 1;
        z-index: 1000;
        width: 100%;
        background-color: white;

      }
    }

    &__mobile-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin: 0 -10px;
    }

    &__mobile-item {
      padding: 10px;
      font-weight: 500;
      font-size: 15px;
      line-height: 155%;
    }

    &__mobile-link {
      font-size: 16px;
      line-height: 120%;
      letter-spacing: -0.02em;
      font-weight: normal;
    }

    &__inner {
      padding: 22px 21px;
      align-items: center;
    }

    &__mobile-block {

    }

    &__logo-wrapper {

    }

    &__logo {
      display: flex;
      align-items: center;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &__mobile-number-button {
      width: 36px;
      height: 36px;
      background-color: white;
      box-shadow: 0 6px 12px rgba(52, 97, 81, 0.07);
      text-align: center;
      border-radius: 50%;
      position: relative;
    }

    &__mobile-icon {
      position: absolute;
      top: 10px;
      left: 11px;
    }

    &__menu-wrapper {
      display: none;
    }

    &__list {

    }

    &__item {

    }

    &__link {

    }

    &__wrapper {
      display: none;
    }

    &__number {
      display: none;
    }

    &__button-wrapper {

    }

    &__button {
      display: none;
    }
  }
}