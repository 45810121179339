.recall-modal {

  &__title {
    font-weight: bold;
    line-height: 110%;
    letter-spacing: -0.02em;
    text-align: center;
  }

  &__sub-title {
    color: var(--grey-text);
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    letter-spacing: -0.02em;
  }

  &__politics {
    font-size: 13px;
    line-height: 130%;
    color: var(--grey-text);

    &_link {
      border-bottom: 1px solid var(--t);
      color: var(--politic);
      transition: var(--default-transition);

      &:hover {
        border-bottom-color: var(--grey-dark-text);
      }
    }
  }

  &__field {
    input[type="text"] {
      border: none;
      background-color: transparent;
      border-bottom: 2px solid var(--grey-border-color);
    }
  }

  &__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: white;
    padding: 22px 79px 22px 22px;

  }
}

@include respond-up('large') {
  .recall-modal {
    padding: 50px 90px;

    &__title {
      font-size: 40px;
      margin-bottom: 20px;
    }

    &__sub-title {
      margin-bottom: 40px;
    }

    &__field {
      margin-bottom: 55px;

    }

    &__action {
      display: flex;
      justify-content: space-between;
    }

    &__button {
      flex: 0 0 30%;
    }

    &__politics {
      flex: 0 0 50%;
    }
  }
}

@include respond-down('medium') {
  .recall-modal {
    &__container {
      padding-top: 50px;

    }

    &__title {
      font-size: 25px;
      margin-bottom: 20px;
    }

    &__sub-title {
      margin-bottom: 20px;
    }

    &__field {
      margin-bottom: 35px;
    }

    &__politics {
      max-width: 280px;
    }

    &__button {
      margin-bottom: 12px;
      padding: 17px 79px 17px 23px;
    }
  }
}