.request-form {
  position: relative;
  background-color: white;

  &__inner {
    display: flex;
  }

  &__title, &__title-green {
    letter-spacing: -0.02em;
  }

  &__title-line-one, &__title-line-two {
    overflow: hidden;
  }

  &.need-animation {
    .request-form {
      &__title {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }

      &__title-second {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }

      &__subtitle {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }

      &__data {
        opacity: 0;
        transform: translate3d(0, 50px, 0);
      }
    }

    .request-form__title-second  {
      color: black;
    }
  }

  &__title-green {
    transition: {
      property: color;
      delay: 1.5s;
      duration: 1s;
      timing-function: ease-in-out;
    };
  }

  &.animate {
    .request-form {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__title-second {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__title-green {
        color: var(--primary-color);

      }

      &__subtitle {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__data {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.8s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
    }
  }


  &__subtitle {
    color: var(--grey-text);
  }

  &__data {
    background-color: white;
    position: relative;
    z-index: 1;
    transition: all 0.4s;
    box-shadow: 0 20px 40px rgba(52, 97, 81, 0.1);
    border-radius: 6px;
  }

  &__success-svg {
    width: 87px;
    height: 87px;
    border-radius: 100%;
    box-shadow: 0 20px 20px rgba(52, 97, 81, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 26px;

    svg {
      width: 33px;
      height: 33px;
    }
  }

  &__input {
    input[type="text"] {
      border: none;
      background-color: transparent;
      border-bottom: 2px solid var(--border-color);
    }

    border: none;
  }

  &__politics {
    font-size: 13px;
    line-height: 130%;
    color: var(--politic-text);

    &_link {
      border-bottom: 1px solid var(--t);
      color: var(--politic);
      transition: var(--default-transition);

      &:hover {
        border-bottom-color: var(--politic);
      }
    }
  }

  &__success {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: {
      property: opacity, transform, visibility;
      duration: 0.4s;
    };
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate3d(0, 40px, 0);

  }

  &__success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 10;
    background-color: white;
    height: 100%;
    width: 100%;

  }

  &.success {
    .request-form {
      &__data {
        opacity: 0.05;
      }

      &__success-title {
        font-weight: 700;
        line-height: 44px;
        margin-bottom: 25px;
      }

      &__success-description {
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        color: var(--grey-text);
      }

      &__success {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }
    }
  }

  &__button {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2em;
    letter-spacing: -0.02em;
    color: white;
    padding: 22px 79px 22px 22px;
  }
}

@include respond-up('large') {
  .request-form {
    margin-bottom: 146px;
    padding-top: 95px;
    background: {
      image: url("/static/images/base/form-background.png");
      repeat: no-repeat;
      position: right;
    };

    &__inner {
      display: flex;
    }

    &__title-wrapper {
      padding-bottom: 25px;
    }

    &__wrapper {
      flex: 0 0 50%;
    }

    &__title {
      max-width: 480px;
      font-size: 45px;
      font-weight: 700;
      line-height: 50px;
    }

    &__subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 23px;
    }

    &__data {
      flex: 0 0 650px;
      padding: 60px;
      margin-bottom: -55px;
      margin-top: -25px;
    }

    &__input {
      margin-bottom: 55px;
      border-bottom: 2px solid black;
    }

    &__button-wrapper {
      display: flex;
      justify-content: space-between;
    }

    &__politics {
      flex: 0 0 55%;
    }

    &__success-wrapper {
      padding-top: 80px;
    }

    &__success-title {
      font-size: 40px;
      font-weight: 700;
      line-height: 44px;
      margin-bottom: 25px;
    }

    &__success-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 23px;
      color: var(--grey-text);
    }
  }
}

@include respond-down('medium') {
  .request-form {

    background: {
      image: url("/static/images/base/form-back-m.png");
      repeat: no-repeat;
      size: cover;
    };
    margin-bottom: 100px;

    &__inner {
      padding-top: 40px;
      flex-direction: column;
    }

    &__wrapper {
      text-align: center;
    }

    &__title-wrapper {
      margin-bottom: 16px;

    }

    &__title {
      font-size: 25px;
      font-weight: 700;
      line-height: 28px;
    }

    &__subtitle {
      margin-bottom: 29px;
    }

    &__data {
      padding: 30px 25px;
      margin-bottom: -35px;
    }

    &__input {
      margin-bottom: 35px;
      border-bottom: 2px solid black;

      &:last-child {
        margin-bottom: 35px;
      }
    }

    &__button {
      margin-bottom: 20px;
      font-size: 17px;
      line-height: 1.2em;
      letter-spacing: -0.02em;
      color: white;
      padding: 17px 79px 17px 23px;
    }

    &__politics {
      max-width: 280px;
    }

    &__success-wrapper {
      padding-top: 110px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__success-title {
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
    }

    &__success-description {
      font-size: 16px;
    }

    &.success {
      .request-form {
        &__data {
        }

        &__success {
        }
      }
    }
  }
}