.floating-field {
  position: relative;

  label {
    font: var(--font);
    color: var(--text-color);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    font-weight: 400;
    display: flex;
    align-items: center;
    pointer-events: none;
    font-size: 17px;
    line-height: 26px;
    transition: all 0.4s;
    margin: 0;
    font-style: normal;
  }

  textarea {
    max-width: 330px;
    min-width: 100%;
    min-height: 45px;
  }

  &._filled label,
  input:focus + label,
  textarea:focus + label {
    font-size: 12px;
    height: 1px;
    width: 100%;
    padding-bottom: 15px;
  }
}

@include respond-up('medium') {
  .floating-field {
    label {
      font-size: 16px;
    }
  }
}

@include respond-down('medium') {
  .floating-field {
    label {
      font-size: 16px;
      height: 30px;
    }

    &._filled label,
    input:focus + label,
    textarea:focus + label {
      font-size: 12px;
    }
  }
}