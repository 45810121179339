/*
spacer - Задаёт фиксированные отступы по краям контейнера.

Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$grid: (
  small: (
    from: 0,
    to: 767,
    spacer: 20
  ),
  medium: (
    from: 768,
    to: 1199,
    spacer: 30
  ),
  large: (
    from: 1200,
    spacer: 40
  )
);

:root {
  --max-row-width: 1280;

  --body-font-size: 16px;
  --body-line-height: 1.3;

  --font: "Roboto", sans-serif;
  --h-font: "Roboto", sans-serif;

  --t: transparent;

  --primary-color: #01c767;
  --green-border-color: #D5EBD9;
  --background-color: #E5E5E5;
  --background-color-gray: #F8F8F8;
  --background-color-another-gray: #F9F9F9;
  --background-color-green: #E4FBE9;
  --border-primary-color: #4BE16C;
  --border-green-light: #E5F6E8;
  --green-arrow: #2EDD54;
  --grey-border-color: #1A3334;
  --grey-light-border-color: #E5EAF2;
  --grey-text: #859996;
  --light-green-background: #E2F3E6;
  --green-text: #2BD055;
  --alert-color: #b50000;
  --hover-viber: #665CAC;
  --hover-telegram: #35A6DE;
  --disable: #DAE6EA;
  --politic: #677E7A;
  --politic-text: rgba(133, 153, 150, 0.47);;

  --default-transition: all 0.4s;
  --outline-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  --primary-color-opacity: rgba(1, 199, 103, .85);
}

