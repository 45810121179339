.what-we-offer {
  background: var(--background-color-gray);


  &__title-wrapper {
    overflow: hidden;
  }

  &.need-animation {
    .what-we-offer {
      &__title {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__tabs-block {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__slider-images-wrap {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }

      &__slider-description-wrap {
        overflow: hidden;
        transform: translate3d(0, 50px, 0);
        opacity: 0;
      }
    }
  }

  &.animate {
    .what-we-offer {
      &__title {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.2s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__tabs-block {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.5s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__slider-images-wrap {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 0.6s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }

      &__slider-description-wrap {
        transform: translate3d(0, 0, 0);
        opacity: 1;
        transition: {
          property: transform, opacity;
          delay: 1s;
          duration: 1s;
          timing-function: ease-in-out;
        };
      }
    }
  }

  &__tabs-list {
    display: inline-flex;
    padding: 5px 3px;
    background: var(--light-green-background);
    border-radius: 50px;
  }

  &__tabs-item {
    color: rgba(30, 66, 61, 0.42);
    margin: 0 3px;

    &._active {
      color: var(--green-text);
      background-color: #FFFFFF;
      border-radius: 50px;
    }
  }

  &__tab-link {
    display: block;
    font-weight: bold;
    font-size: 15px;
    line-height: 17/15*1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }

  &__content-container {
    position: relative;
  }

  &__image-wrap {
    position: relative;
  }

  &__info-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.15em;
    letter-spacing: 0.1em;
    color: var(--green-text);
    text-transform: uppercase;
  }

  &__slider-images-wrap {
    border-radius: 10px;
    border: 1px solid var(--border-green-light);
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    padding: 6px;
  }

  &__slider-images-item {
    position: relative;
  }

  &__picture {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  &__slider-description-wrap {
    background-color: white;
    box-shadow: 0 20px 60px rgba(26, 61, 94, 0.14);
    border-radius: 10px;
  }

  &__slider-arrows {
    width: 120px;
    display: flex;
    justify-content: space-between;
  }

  .tns-nav {
    position: absolute;
    bottom: -46px;
    left: 21px;
  }

  &__tabs-block {
    display: flex;
    justify-content: center;
  }

  .tns-outer {
    border-radius: 6px;
    overflow: hidden;
  }
}

@include respond-up('large') {
  .what-we-offer {
    padding: 127px 0 190px;

    &__tabs-container {
      width: fit-content;
      margin-top: 50px;
    }

    &__tab-link {
      padding: 21px 47px;
    }

    &__content-container {
      margin-top: 50px;
    }

    &__info-subtitle {
      margin-top: 36px;
    }

    &__description {
      margin-top: 15px;
    }

    &__slider-images-wrap {
      max-width: 767px;
    }

    &__slider-description-wrap {
      position: absolute;
      padding: 50px;
      top: 182px;
      right: 0;
      width: calc((551 / 1500) * 100vw);
      height: calc((312 / 1500) * 100vw);
      max-width: 551px;
      max-height: 312px;
    }

    &__slider-arrows {
      position: absolute;
      right: 0;
      top: 75px;
    }

    &__image-wrap {
      width: 100%;
      height: 390px;
    }
  }
}

@include respond-down('medium') {
  .what-we-offer {
    padding: 30px 0 40px;

    &__tabs-container {
      overflow-x: scroll;
      margin: 25px -20px 0 -20px;
      padding: 0 20px;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__tab-link {
      padding: 16px 47px;
    }

    &__content-container {
      margin-top: 30px;
    }

    &__info-subtitle {
      margin-top: 22px;
    }

    &__description {
      margin-top: 11px;
    }

    &__slider-images-wrap {
      z-index: 5;
    }

    &__slider-description-wrap {
      padding: 25px;
      margin: -22px auto 0;
      width: calc((304 / 375) * 100vw);
      position: relative;
      z-index: 10;
    }

    &__slider-arrows-container {
      margin-top: 18px;
      display: flex;
      justify-content: center;
    }

    &__image-wrap {
      width: 100%;
      height: calc((180 / 375) * 100vw);
    }
  }
}